import * as React from 'react'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'

import { styled } from '@mui/material/styles'

const thumbSize = 18

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: `translateX(${thumbSize}px)`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--color-theme-main)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      // color: '#33cf4d',
      // border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      // color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      // opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: thumbSize,
    height: thumbSize,
  },
  '& .MuiSwitch-track': {
    // borderRadius: 26 / 2,
    borderRadius: 16,
    backgroundColor: '#E6E6E8',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

type TSwitchComponent = { label?: string; checked?: boolean; onChange: (checked: boolean) => void }

export default function SwitchComponent({ label, onChange, checked }: TSwitchComponent) {
  const handleChange = (_, checked) => onChange(checked)
  if (label) {
    return (
      <FormGroup>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} onChange={handleChange} />}
          label={label}
          checked={checked}
        />
      </FormGroup>
    )
  }
  return (
    <IOSSwitch
      onChange={handleChange}
      checked={checked}
      // sx={{ m: 1 }}
      // defaultChecked
    />
  )
}
