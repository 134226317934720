import React, { useCallback, useEffect, useState } from 'react'
import NextImage from 'next/image'

import Modal from '@components/Modal/Modal'

import { useDropzone } from 'react-dropzone'
import ImageCropper from './components/ImageCropper/ImageCropper'
import Compress from 'react-image-file-resizer'

import IconCamera from '@assets/assets/icons/profile-app/camera-small.svg'
import IconClose from '@assets/assets/icons/close-purple.svg'

import classes from './Avatar.module.scss'

const IMAGE_SIZE_LIMIT = 2500000 // 2.5 Megabytes

type TAvatar = {
  avatar?: any
  onChange: (url?: string) => void
}

export default function Avatar({ avatar, onChange }: TAvatar) {
  const [isCropperOpen, setIsCropperOpen] = useState<any>(false)
  const [avatarClasses, setAvatarClasses] = useState(classes.avatar)
  const [imageToCrop, setImageToCrop] = useState<any>(null)
  const [error, setError] = useState('')

  const avatarSrc =
    avatar?.includes('znaka-default-avatar.jpg') || !avatar ? '/assets/kid-image.svg' : avatar

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0]
      // const imgToCrop = URL.createObjectURL(file) // without compression

      const compressedImg = new Promise((resolve, reject) => {
        Compress.imageFileResizer(
          file, // the file from input
          1024, // width
          1024, // height
          'JPEG', // compress format WEBP, JPEG, PNG
          100, // quality
          0, // rotation
          (uri) => {
            if (uri) {
              resolve(uri)
            } else {
              reject('Cannot add photo: ' + file.name)
            }
          },
          'file' // blob, file or base64 default base64
        )
      })

      const compressedImgFile = await compressedImg
      // @ts-ignore
      const compressedImgFileToCrop = URL.createObjectURL(compressedImgFile)

      setImageToCrop(compressedImgFileToCrop)
      setIsCropperOpen(true)
      setError('')
    }
  }, [])

  const imageSizeValidator = (file: File) => {
    if (file.size > IMAGE_SIZE_LIMIT) {
      setError('Avatar size limit - 2.5mb')

      return {
        code: '200',
        message: 'Avatar size limit - 2.5mb',
      }
    }

    return null
  }

  const {
    getRootProps,
    getInputProps,
    open: openDropZone,
    isDragActive,
  } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: 1,
    validator: imageSizeValidator,
    noClick: true,
  })

  useEffect(() => {
    if (isDragActive) {
      setAvatarClasses(`${classes.avatar} ${classes.avatar_dragable}`)
    } else {
      setAvatarClasses(classes.avatar)
    }
  }, [isDragActive])

  const handleCloseModal = () => {
    setIsCropperOpen(false)
    setImageToCrop('')
  }

  const handleCropperChange = (imageUrl: any) => {
    onChange(imageUrl)
  }

  const handleClearImage = () => {
    onChange()
  }

  return (
    <div className={classes.root}>
      <div className={avatarClasses} {...getRootProps()}>
        <input {...getInputProps()} />
        <NextImage
          className={classes.image}
          src={avatarSrc}
          fill
          alt="Add Kid's Avatar"
          onClick={openDropZone}
        />
        <IconCamera className={classes.iconCamera} onClick={openDropZone} />
        {avatar && (
          <button type="button" className={classes.clear} onClick={handleClearImage}>
            <IconClose className={classes.iconClose} />
          </button>
        )}
      </div>
      {error && <span className={classes.error}>{error}</span>}
      <Modal
        isOpen={isCropperOpen}
        onClose={handleCloseModal}
        id="modal-kid-avatar-crop"
        // isLoading={isLoading}
      >
        <ImageCropper
          inputImage={imageToCrop}
          onClose={handleCloseModal}
          onChange={handleCropperChange}
        />
      </Modal>
    </div>
  )
}
