import React from 'react'
import classes from './Heading.module.scss'

type THeading = {
  title: string
  text: string
  success?: boolean
}

export default function Heading({ title, text, success }: THeading) {
  let textClasses = classes.text

  if (success) {
    textClasses += ` ${classes.text_success}`
  }
  return (
    <div className={classes.root}>
      <b className={classes.title}>{title}</b>
      <p className={textClasses}>{text}</p>
    </div>
  )
}
