import React, { ReactElement, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useDebouncedCallback } from 'use-debounce'

import IconClose from '@assets/assets/icons/close-purple.svg'

import classes from './Modal.module.scss'
import Loader from '@components/Loader/Loader'

type TModal = {
  isOpen: boolean
  onClose: Function
  children: ReactElement
  hideCloseButton?: boolean
  id: string
  isLoading?: boolean
  containerStyle?: any
  bodyStyle?: any
}

function Modal({
  isOpen = false,
  onClose,
  children,
  hideCloseButton,
  id,
  isLoading,
  containerStyle,
  bodyStyle,
}: TModal) {
  const DEBOUNCE_DELAY = 300

  const handleEscKeyPress = useDebouncedCallback(() => {
    const onEscKeyPress = (event: any) => {
      if (event.keyCode === 27 && isOpen) {
        onClose()
      }
    }

    document.addEventListener('keydown', onEscKeyPress, false)
  }, DEBOUNCE_DELAY)

  useEffect(() => {
    if (isOpen) {
      // prevent page scroll when modal is open
      document.body.style.overflow = 'hidden'
      handleEscKeyPress()
    } else {
      document.body.style.overflow = 'unset'
    }

    // prevent page scroll when modal is open
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
    return () => {
      document.body.style.overflow = 'unset'
      handleEscKeyPress()
    }
  }, [isOpen])

  const handleModalClose = (e: any) => {
    if (e) {
      e?.preventDefault()
    }
    onClose()
  }

  const handleRootClick = (e: any) => {
    if (e.target.id === id) {
      handleModalClose(e)
    }
  }

  if (!isOpen) {
    return null
  }

  return createPortal(
    <div className={classes.root} id={id} onClick={handleRootClick}>
      {isLoading && <Loader />}
      <div className={classes.body} style={bodyStyle || {}}>
        {!hideCloseButton && <IconClose className={classes.close} onClick={handleModalClose} />}
        <div className={classes.container} style={containerStyle || {}}>
          {children}
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
