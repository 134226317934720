import { gql } from '@apollo/client'

export const GET_EXPERIENCES = gql`
  query getOptions($name: String) {
    experiences: allOptions(name: $name) {
      id
      name
      description
    }
  }
`

export const GET_PRICEGROUPS = gql`
  query allPricegroups {
    allPricegroups {
      id
      name
      markCount
    }
  }
`

export const GET_EXPERIENCES_COUNT = gql`
  query getExperiencesCount(
    $state: String
    $city: String
    $northEast: PointInput
    $southWest: PointInput
    $experience: String
  ) {
    experiencesCount(
      state: $state
      city: $city
      northEast: $northEast
      southWest: $southWest
      experience: $experience
    ) {
      id
      count
    }
  }
`

export const GET_EXPERIENCES_NEW_COUNT = gql`
  query experiencesCountNew($input: ResultsCityInput) {
    experiencesCountNew(input: $input) {
      id
      count
      name
    }
  }
`
