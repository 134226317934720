import React, { ReactElement } from 'react'

import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--color-background)',
    color: 'var(--color-text-secondary)',
    boxShadow: '0 4 9px rgba(0, 0, 0, 0.25)',
    fontSize: 14,
    lineHeight: '130%',
    padding: 20,
    borderRadius: 20,
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))

type TToolTip = { title: string; children: ReactElement<any, any> } & TooltipProps

export default function ToolTip({ title, children, ...props }: TToolTip) {
  return (
    <ClickAwayListener onClickAway={props?.onClose ? props?.onClose : () => null}>
      <LightTooltip {...props} title={title} arrow>
        {children}
      </LightTooltip>
    </ClickAwayListener>
  )
}
