import React, { ElementType } from 'react'

import classes from './ButtonAdd.module.scss'

type TButtonAdd = {
  text: string
  onClick: () => void
  Icon?: ElementType
  collapsed?: boolean
  theme?: 'white'
  disabled?: boolean
  isActive?: boolean
}

export default function ButtonAdd({
  text,
  onClick,
  Icon,
  collapsed,
  disabled,
  theme,
  isActive,
}: TButtonAdd) {
  let rootClasses = classes.root

  if (collapsed) {
    rootClasses += ` ${classes.root_collapsed}`
  }

  if (isActive) {
    rootClasses += ` ${classes.root_active}`
  }

  if (theme === 'white') {
    rootClasses += ` ${classes.root_white}`
  }

  return (
    <button type={'button'} onClick={onClick} className={rootClasses} disabled={disabled}>
      <div className={classes.icon}>{Icon ? <Icon /> : '+'}</div>
      <span className={classes.text}>{text}</span>
    </button>
  )
}
