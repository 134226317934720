import { Kid } from '../../../types'

export const getFiltersFromUrlParams = (params) => {
  return {
    ages: params.ages ? params.ages.split(',').map(Number) : [],
    experiences: params.experiences ? params.experiences.split(',') : [],
    activeKids: params.activeKids
      ? params.activeKids.split(',').map((uid) => ({ uid }) as Kid)
      : [],
  }
}
