import React, { createContext, useState, useContext, useEffect } from 'react'
import { Kid } from '../../types'
import { getFiltersFromUrlParams } from '@/utils/filters/getFiltersFromUrl'

export type Filters = {
  ages: number[]
  experiences: string[]
  activeKids: Kid[]
}

export type FiltersContextType = {
  filters: Filters
  initialFilters: Filters
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
  setInitialFilters: React.Dispatch<React.SetStateAction<Filters>>
  // resetFilters: () => void
  updateUrl: (props: Filters) => void
}

const defaultFilters: Filters = {
  ages: [],
  experiences: [],
  activeKids: [],
}

export const FiltersContext = createContext<FiltersContextType>({
  filters: defaultFilters,
  initialFilters: defaultFilters,
  setFilters: () => {},
  setInitialFilters: () => {},
  // resetFilters: () => {},
  updateUrl: () => {},
})

export const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [filters, setFilters] = useState<Filters>(defaultFilters)
  const [initialFilters, setInitialFilters] = useState<Filters>(defaultFilters)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const urlParams = {
      ages: params.get('ages'),
      experiences: params.get('experiences'),
      activeKids: params.get('activeKids'),
    }

    const filtersFromParams = getFiltersFromUrlParams(urlParams)

    const initialFilters: Filters = { ...filtersFromParams }
    setFilters(initialFilters)
    setInitialFilters(initialFilters)
  }, [])

  const updateUrl = ({ ages, experiences, activeKids }: Filters) => {
    const params = new URLSearchParams(window.location.search)

    if (ages.length > 0) {
      params.set('ages', ages.join(','))
    } else {
      params.delete('ages')
    }

    if (experiences.length > 0) {
      params.set('experiences', experiences.join(','))
    } else {
      params.delete('experiences')
    }

    if (activeKids.length > 0) {
      params.set('activeKids', activeKids.map((k) => k.uid).join(','))
    } else {
      params.delete('activeKids')
    }

    window.history.pushState(null, '', `?${params.toString()}`)
  }

  // const resetFilters = () => {
  //   setFilters(initialFilters)
  // }

  return (
    <FiltersContext.Provider
      value={{
        filters,
        initialFilters,
        setFilters,
        setInitialFilters,
        // , resetFilters
        updateUrl,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export const useFilters = () => useContext(FiltersContext)
