import React from 'react'

import { FormControl } from '@material-ui/core'

import { CustomLabel, CustomInput } from './CustomInput'

import classes from './Input.module.scss'

export type Input = {
  id: string
  placeholder?: string
  label?: string
  type?: string
  error?: boolean
  defaultValue?: string
  helperText?: string
}

type TInput = {
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
} & Input

export default function Input({
  id,
  label,
  placeholder,
  type,
  error,
  onChange,
  helperText = '',
  value,
  disabled,
}: TInput) {
  let helperTextClasses = classes.helper

  if (error) {
    helperTextClasses += ` ${classes.helper_danger}`
  }

  return (
    <FormControl className={classes.root} error={error}>
      {label && (
        <CustomLabel shrink htmlFor={id}>
          {label}
        </CustomLabel>
      )}
      <CustomInput
        // defaultValue={defaultValue} // controlled / uncontrolled input warning
        id={id}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
      {helperText && <span className={helperTextClasses}>{helperText}</span>}
    </FormControl>
  )
}
