import React from 'react'

import classes from './Button.module.scss'

type TButton = {
  label: string
  onClick?: (e?: any) => void
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  style?: 'secondary' | 'danger'
  type?: 'submit' | 'button'
  disabled?: boolean
  inlineStyles?: React.CSSProperties
}

export default function Button({
  label,
  onClick,
  Icon,
  style,
  type,
  disabled,
  inlineStyles,
}: TButton) {
  let rootClasses = classes.root

  if (style === 'secondary') {
    rootClasses += ` ${classes.root_secondary}`
  }

  if (style === 'danger') {
    rootClasses += ` ${classes.root_danger}`
  }

  if (disabled) {
    rootClasses += ` ${classes.root_disabled}`
  }

  if (type === 'submit') {
    return (
      <button className={rootClasses} type={type} disabled={disabled} style={inlineStyles}>
        {Icon && <Icon className={classes.icon} />}
        {label}
      </button>
    )
  }

  return (
    <div className={rootClasses} onClick={!disabled ? onClick : () => null} style={inlineStyles}>
      {Icon && <Icon className={classes.icon} />}
      {label}
    </div>
  )
}
