import React, { useState, useEffect } from 'react'

import Cropper from 'react-easy-crop'
import Button from '../../../Button/Button'

import classes from './ImageCropper.module.scss'

type TImageCropper = {
  inputImage: any
  onClose: () => void
  onChange: (image: string) => void
}

export default function ImageCropper({ inputImage, onClose, onChange }: TImageCropper) {
  const [image, setImage] = useState('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState(null)
  // const [aspectRatio, setAspectRatio] = useState(4 / 4)

  useEffect(() => {
    if (inputImage) {
      setImage(inputImage)
    }
  }, [inputImage])

  const onCropComplete = (_croppedAreaPercentage: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels)
  }

  // const onAspectRatioChange = (event: any) => {
  //   setAspectRatio(event.target.value)
  // }

  const onCropDone = (imgCroppedArea: any) => {
    const mimeType = 'image/jpeg'
    const canvas = document.createElement('canvas')
    canvas.width = imgCroppedArea.width
    canvas.height = imgCroppedArea.height

    const ctx = canvas.getContext('2d')

    let imageObj1 = new Image()

    imageObj1.src = image
    imageObj1.onload = function () {
      ctx?.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      )

      const dataURL = canvas.toDataURL(mimeType)

      onChange(dataURL)
      onClose()
    }
  }

  const onCropCancel = () => {
    onClose()
    setImage('')
  }

  return (
    <div className={classes.root}>
      <div className={classes.cropper}>
        <Cropper
          image={image}
          aspect={4 / 4}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={(zoomValue) => {
            setZoom(zoomValue)
          }}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: '100%',
              height: '100%',
              backgroundColor: '#fff',
            },
          }}
        />
      </div>
      <div className={classes.controls}>
        <div className={classes.button}>
          <Button label="Cancel" type="button" style="danger" onClick={onCropCancel} />
        </div>
        <div className={classes.button}>
          <Button onClick={() => onCropDone(croppedArea)} label="Done" type="button" />
        </div>
      </div>
    </div>
  )
}
