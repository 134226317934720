import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

import Modal from '@components/Modal/Modal'
import Heading from '@components/Modal/components/Heading/Heading'
import Form from '@components/Filters/components/Kids/components/AddKidModal/components/Form/Form'

import {
  Kid,
  // , User
} from '../../../types' // old types

import { GET_EXPERIENCES } from '@components/Filters/queries'
// import useKids from '@/hooks/useKids'

type TAddKidModal = {
  isOpen: boolean
  onClose: (refetch?: boolean) => void
  // editableKid: Kid | null
  // user?: User | null
}

export default function AddKidModal({
  isOpen,
  onClose,
  // user,
}: TAddKidModal) {
  let editableKid = null // todo add kid which is edditing now
  let user: any = null // todo get from context

  const [getExperiences, { data: experiencesData, loading }] = useLazyQuery(GET_EXPERIENCES, {
    variables: { name: 'experience' },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (isOpen) {
      getExperiences()

      trackGAEvent({
        action: `modal_open`,
        category: 'Filters Bar Kids Modal',
        label: `Modal Opened`,
        user,
      })
    }
  }, [isOpen])

  // add kid
  const handleSubmitKid = async (data: Kid) => {
    try {
      const { first, last, birthdate, interests, avatar } = data

      const formData = new FormData()

      formData.append('first', `${first}`)
      formData.append('last', `${last}`)
      formData.append('birthdate', `${birthdate}`)

      if (interests && Boolean(interests?.length)) {
        for (let i = 0; i < interests.length; i++) {
          formData.append('interests[]', `${interests[i]}`)
        }
      }

      if (avatar) {
        formData.append('avatar', avatar)
      }

      const res = await fetch(`${process.env.NEXT_PUBLIC_GIS_HOME}/api/user/kids/add`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      })

      if (res.status === 200) {
        onClose(true)

        // update kids
        // todo unset editable
        // setEditableKid(null)

        trackGAEvent({
          action: `event`,
          category: 'Filters Bar Kids Modal',
          label: `Kid Successfully Added`,
          user,
        })
      }
    } catch (e) {
      console.log('handleSubmitKid error', e)
    }
  }

  // update kid
  const handleSaveKid = async (data: Kid) => {
    try {
      const { first, last, birthdate, interests, avatar, uid } = data

      const formData = new FormData()

      formData.append('first', `${first}`)
      formData.append('last', `${last}`)
      formData.append('birthdate', `${birthdate}`)
      formData.append('uid', `${uid}`)

      if (interests && Boolean(interests?.length)) {
        for (let i = 0; i < interests.length; i++) {
          formData.append('interests[]', `${interests[i]}`)
        }
      }

      // empty string of avatar will delete kid's avatar.
      formData.append('avatar', avatar || '')

      const res = await fetch(`${process.env.NEXT_PUBLIC_GIS_HOME}/api/user/kids/save`, {
        method: 'PUT',
        body: formData,
        credentials: 'include',
      })

      if (res.status === 200) {
        onClose(true)

        // update kids
        // todo unset editable
        // setEditableKid(null)

        trackGAEvent({
          action: `event`,
          category: 'Filters Bar Kids Modal',
          label: `Kid Successfully Edited`,
          data: `kid id: ${uid}`,
          user,
        })
      }
    } catch (e) {
      console.log('handleSaveKid error', e)
    }
  }

  const handleDeleteKid = async (id: string) => {
    try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_GIS_HOME}/api/user/kids/delete`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ id }),
      })

      if (res.status === 200) {
        onClose(true)

        // update kids
        // todo unset editable
        // setEditableKid(null)

        trackGAEvent({
          action: `event`,
          category: 'Filters Bar Kids Modal',
          label: `Kid Successfully Deleted`,
          data: `kid id: ${id}`,
          user,
        })
      }
    } catch (e) {
      console.log('handleDeleteKid error', e)
    }
  }

  const handleClose = () => {
    // todo unset editable
    // if (editableKid) {
    //   setEditableKid(null)
    // }

    onClose()

    trackGAEvent({
      action: `modal_close`,
      category: 'Filters Bar Kids Modal',
      label: `Modal Closed`,
      user,
    })
  }

  return (
    <Modal id={'modal-add-kid'} isOpen={isOpen} onClose={handleClose}>
      <div>
        <div>
          <Heading
            title={editableKid ? 'Edit kid' : 'Add kid'}
            text={
              editableKid
                ? ''
                : 'Welcome to BeAKid where we give you the most complete view of activities for your children'
            }
          />
        </div>
        <Form
          editableKid={editableKid}
          allExperiences={experiencesData && experiencesData?.experiences}
          loading={loading}
          onSubmit={handleSubmitKid}
          onSave={handleSaveKid}
          onDelete={handleDeleteKid}
        />
      </div>
    </Modal>
  )
}
