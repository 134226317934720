import React from 'react'
import Image from 'next/image'

import { Kid as TypeKid } from '../../../../../types'

import classes from './Kid.module.scss'
import Switch from '@/components/ui/Switch/Switch'

type TKid = {
  theme?: 'light'
  checked: boolean
  onChange: ({ checked, kid }: { checked: boolean; kid: TypeKid }) => void
} & TypeKid

export default function Kid({ theme, checked, onChange, ...kid }: TKid) {
  if (!kid) {
    return null
  }

  let rootClasses = classes.root

  if (theme === 'light') {
    rootClasses += ` ${classes.root_light}`
  }

  return (
    <div className={rootClasses}>
      <span className={classes.name}>{kid.first}</span>
      <div className={classes.button}>
        <div className={classes.avatar}>
          <Image src={`${kid.avatar}`} width={38} height={38} alt={`${kid.first}'s avatar`} />
        </div>
        {/*todo edit button*/}
        <div className={classes.switch}>
          <Switch onChange={(checked) => onChange({ kid, checked })} checked={checked} />
        </div>
      </div>
    </div>
  )
}
