import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from '@material-ui/core/styles/styled'

import styles from './Loader.module.scss'

const CustomCircularProgress = styled(CircularProgress)(() => ({
  '&.MuiCircularProgress-colorPrimary': {
    color: `#1379FF`,
    marginLeft: '20px',
  },
}))

export default function Loader() {
  return (
    <div className={styles.Loader}>
      <span>Loading...</span>
      <CustomCircularProgress size={30} />
    </div>
  )
}
