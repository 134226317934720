import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { FormControl, Paper, TextField, Radio, Checkbox } from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'

import IconCheckbox from '@assets/assets/icons/profile-app/checkbox.svg'
import IconCheckboxChecked from '@assets/assets/icons/profile-app/checkbox-checked.svg'
import IconRadio from '@assets/assets/icons/profile-app/radio.svg'
import IconRadioChecked from '@assets/assets/icons/profile-app/radio-checked.svg'
import IconChevronDown from '@assets/assets/icons/profile-app/chevron-down.svg'

import { CustomLabel } from '../CustomInput'

import classes from '../Input.module.scss'

import { Input as InputType } from '../Input'

const CustomPaper = withStyles(() => ({
  root: {
    fontFamily: 'var(--font-lato)',
    borderRadius: 12,
    border: '1px solid #D2D2D7',
    '& .MuiAutocomplete-listbox': {},
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: 'transparent',
    },
    '& .MuiAutocomplete-option[data-focus="true"] li': {
      color: '#9b6eff',
    },
  },
}))(Paper)

const CustomCheckbox = withStyles(() => ({
  root: {
    padding: 0,
    margin: 0,
    marginRight: '4px',
  },
}))(Checkbox)

const CustomRadio = withStyles(() => ({
  root: {
    padding: 0,
    margin: 0,
    marginRight: '4px',
  },
}))(Radio)

const CustomInput = withStyles((theme) => ({
  root: {
    paddingTop: '21px',
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment': {
      right: 16,
    },
    '& .MuiAutocomplete-tag': {
      height: 'auto',
    },
    '&.Mui-error .MuiInputBase-input': {
      borderColor: '#fe6031',
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      height: 'auto',
      padding: 0,
    },
    '& .MuiAutocomplete-inputRoot.Mui-focused .MuiOutlinedInput-notchedOutline': {
      outline: 'none',
      border: 'none',
    },
    '& .MuiAutocomplete-inputRoot': {
      width: '100%',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '28px',
      padding: '0 16px',
      minHeight: '48px',
      fontFamily: 'var(--font-lato)',
      borderRadius: 12,
      border: '1px solid #D2D2D7',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&.Mui-focused': {
        borderColor: '#9B6EFF',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      },
      '&:focus': {
        borderColor: '#9B6EFF',
      },
      '&::placeholder': {
        color: '#D2D2D7',
        opacity: 1,
      },
    },
  },
}))(TextField)

type TSelect = {
  value?: any[]
  onChange: (options: any[]) => void
  options: any[]
  multiple?: boolean
} & InputType

export default function Select({
  id,
  label,
  placeholder,
  error,
  onChange,
  helperText,
  value,
  options,
  multiple,
}: TSelect) {
  let helperTextClasses = classes.helper

  if (error) {
    helperTextClasses += ` ${classes.helper_danger}`
  }
  const handleOnChange = (_event: any, value: any) => {
    onChange(value)
  }

  return (
    <FormControl className={classes.root} error={error}>
      {label && (
        <CustomLabel shrink htmlFor={id}>
          {label}
        </CustomLabel>
      )}
      <Autocomplete
        disablePortal={true}
        // open={true} // drop down debugger
        multiple={multiple}
        id={id}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.name}
        renderOption={(props, { selected }) => {
          return (
            <li {...props} className={classes.listOption}>
              {multiple ? (
                <CustomCheckbox
                  icon={<IconCheckbox />}
                  checkedIcon={<IconCheckboxChecked />}
                  checked={selected}
                />
              ) : (
                <CustomRadio
                  icon={<IconRadio />}
                  checkedIcon={<IconRadioChecked />}
                  checked={selected}
                />
              )}
              {props.name}
            </li>
          )
        }}
        onChange={handleOnChange}
        value={value}
        defaultValue={value}
        renderInput={(params) => (
          <CustomInput {...params} placeholder={placeholder} variant="outlined" />
        )}
        PaperComponent={({ children }) => <CustomPaper>{children}</CustomPaper>}
        popupIcon={<IconChevronDown />}
      />
      {helperText && <span className={helperTextClasses}>{helperText}</span>}
    </FormControl>
  )
}
