import React, { useState, useEffect } from 'react'

import Avatar from '../Avatar/Avatar'
import Input from './components/Input/Input'
import InputDate from './components/Input/components/InputDate'
import Select from './components/Input/components/Select'
import Button from '../Button/Button'

import { formatYYYYMMDD } from '@helpers/formatters/dateFormatters'

import classes from './Form.module.scss'

import { Experience, Kid } from '../../../../../../../../types'

type TForm = {
  editableKid: Kid | null
  onSubmit: (data: Kid) => void
  onDelete: (id: string) => void
  onSave: (data: Kid) => void
  allExperiences: Experience[]
  loading: boolean
}

export default function Form({
  onSubmit,
  editableKid,
  onDelete,
  onSave,
  allExperiences,
  loading,
}: TForm) {
  const [name, setName] = useState(editableKid?.first || '')
  const [lastName, setLastName] = useState(editableKid?.last || '')
  const [birthdate, setBirthdate] = useState(new Date(editableKid?.birthdate || new Date()))
  const [experiences, setExperiences] = useState<any[]>([])
  const [experienceOptions, setExperienceOptions] = useState<any[]>([])

  const [avatarUrl, setAvatarUrl] = useState(editableKid?.avatar || '') // base64

  const [error, setError] = useState('')

  useEffect(() => {
    if (allExperiences) {
      setExperienceOptions(allExperiences)

      if (editableKid?.interests?.length) {
        const interests: any[] = []

        editableKid?.interests.forEach((id) => {
          interests.push(allExperiences?.find((item: any) => item.id === Number(id)))
        })

        setExperiences(interests)
      }
    }
  }, [JSON.stringify(allExperiences)])

  const handleNameChange = (e: any) => {
    setName(e.target.value)
    setError('')
  }

  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value)
    setError('')
  }

  const handleBirthdayChange = (value: any) => {
    setBirthdate(value)
    setError('')
  }

  const handleExperiencesChange = (value: number[]) => {
    setExperiences(value)
    setError('')
  }

  const handleAvatarChange = (url?: string) => {
    setAvatarUrl(url || '')
  }

  const handleSave = (e) => {
    if (e) {
      e.preventDefault()
    }
    if (onSave) {
      onSave({
        uid: editableKid?.uid,
        first: name,
        last: lastName,
        birthdate: formatYYYYMMDD(birthdate),
        interests: experiences.map((item) => item.id),
        avatar: avatarUrl, // base64
      })
    }
  }

  const handleDelete = (e: any) => {
    if (e) {
      e.preventDefault()
    }
    if (editableKid?.uid && onDelete) {
      onDelete(editableKid.uid)
    }
  }

  const handleSubmit = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    if (onSubmit) {
      onSubmit({
        first: name,
        last: lastName,
        birthdate: formatYYYYMMDD(birthdate),
        interests: experiences.map((item) => item.id),
        avatar: avatarUrl, // base64
        // avatar: avatarFile,
      })
    }
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit} encType="multipart/form-data">
      <Avatar onChange={handleAvatarChange} avatar={avatarUrl} />
      <div className={classes.input}>
        <Input
          label="Name or Nickname"
          id="modal-kid-name"
          placeholder="Name or Nickname"
          error={Boolean(error)}
          helperText={error}
          onChange={handleNameChange}
          defaultValue={name}
          value={name}
        />
      </div>
      <div className={classes.input}>
        <Input
          label="Last Name"
          id="modal-kid-name"
          placeholder="Last Name"
          error={Boolean(error)}
          helperText={error}
          onChange={handleLastNameChange}
          defaultValue={lastName}
          value={lastName}
        />
      </div>
      <div className={classes.input}>
        <InputDate
          label="Birthday"
          id="modal-kid-birthday"
          error={Boolean(error)}
          helperText={error}
          onChange={handleBirthdayChange}
          value={birthdate}
        />
      </div>
      <div className={classes.input}>
        <Select
          label="Choose your kids`s interests"
          id="modal-kid-interests"
          placeholder="Choose one or more options"
          onChange={handleExperiencesChange}
          value={experiences}
          options={experienceOptions}
          error={experiences?.length <= 0 && !loading ? true : Boolean(error)}
          helperText={experiences?.length <= 0 && !loading ? 'Select as minimum one option' : error}
          multiple
        />
      </div>
      <div className={classes.buttons}>
        {editableKid ? (
          <>
            <div className={classes.button}>
              <Button
                label="Delete"
                type="button"
                style="danger"
                onClick={(e) => handleDelete(e)}
              />
            </div>
            <div className={classes.button}>
              <Button
                onClick={(e) => handleSave(e)}
                label="Save"
                type="button"
                disabled={
                  Boolean(error) ||
                  !Boolean(name) ||
                  !Boolean(lastName) ||
                  !Boolean(birthdate) ||
                  !Boolean(experiences?.length)
                }
              />
            </div>
          </>
        ) : (
          <Button
            label="Add kids"
            type="submit"
            disabled={
              Boolean(error) ||
              !Boolean(name) ||
              !Boolean(lastName) ||
              !Boolean(birthdate) ||
              !Boolean(experiences?.length)
            }
          />
        )}
      </div>
    </form>
  )
}
