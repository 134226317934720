import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import Icon from '@/assets/icons/arrows/chervon-bottom-blue-18x18.svg'
import { styled } from '@mui/material/styles'

const brakePoints = {
  mobile: '390px',
  tablet: '834px',
  desktop: '1440px',
}

const paddingMenuItem = '20px'

const StyledPaper = styled(Paper)({
  '&.MuiPaper-root': {
    backgroundColor: 'var(--color-background)',
    borderRadius: 'var(--border-radius-dropdown)',
    minWidth: 221,
    boxShadow: 'var(--box-shadow-dropdown)',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
    },
    '& .MuiAutocomplete-option': {
      position: 'relative',
      gap: 20,
      padding: paddingMenuItem,
      fontSize: 'var(--font-size-description-small)',
      lineHeight: 'var(--line-height-description-small)',
      color: 'var(--color-text-description-small-main)',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        height: 1,
        width: `calc(100% - (${paddingMenuItem} * 2))`,
        left: paddingMenuItem,
        backgroundColor: 'var(--color-devider)',
      },
      '&:active': {
        backgroundColor: 'var(--color-bg-dropdown--active)',
      },
      '&:hover': {
        backgroundColor: 'var(--color-bg-dropdown--hover)',
      },
      '&:focus': {
        backgroundColor: 'var(--color-bg-dropdown--hover)',
      },
    },
  },
})

const StyledAutocomplete = styled(Autocomplete)({
  '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
    paddingRight: '37px',
    maxHeight: '41px',
  },
  [`@media (min-width: ${brakePoints.tablet})`]: {
    '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
      paddingRight: '54px',
    },
  },
})

const StyledAutocompleteInput = styled(TextField)({
  '& .MuiInputBase-root': {
    minWidth: '119px',
    borderRadius: '30px',
    backgroundColor: 'transparent',
    paddingLeft: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
    border: `1px solid var(--color-theme-main)`,
    '&.Mui-focused': {
      borderWidth: '1px',
      outline: 'none',
    },
    '& .MuiAutocomplete-input': {
      fontSize: '12px',
      lineHeight: '20px',
      color: 'var(--color-theme-main)',
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '12px',
      width: '16px',
      height: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [`@media (min-width: ${brakePoints.tablet})`]: {
      minWidth: '187px',
      paddingLeft: '30px',
      '& .MuiAutocomplete-input': {
        fontSize: '16px',
      },
      '& .MuiAutocomplete-endAdornment': {
        right: '30px',
        width: '18px',
        height: '18px',
      },
    },
  },
})

type TAutocomplete = {
  options: any[]
  label?: string
  id: string
  value: any
  onChange: (event: React.ChangeEvent<{}>, value: any) => void
  getOptionLabel: (option: any) => string
  renderOption: (props: any, option: any) => JSX.Element
}

export default function AutocompleteComponent({
  options,
  label,
  id,
  value,
  onChange,
  getOptionLabel,
  renderOption,
}: TAutocomplete) {
  return (
    <StyledAutocomplete
      // open={true} //debugger
      disablePortal
      id={id}
      value={value}
      options={options}
      disableClearable
      popupIcon={<Icon />}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => <StyledAutocompleteInput {...params} label={label} />}
      PaperComponent={StyledPaper}
      componentsProps={{
        popper: {
          style: {
            width: 'fit-content',
          },
        },
      }}
    />
  )
}
