import { withStyles } from '@material-ui/core/styles'
import { InputLabel, InputBase } from '@material-ui/core'

export const CustomLabel = withStyles(() => ({
  root: {
    fontFamily: 'var(--font-lato)',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    color: '#282828',
    transform: 'none',
    top: '-7px',
    '&.Mui-focused': {
      color: '#282828',
    },
    '&.Mui-error': {
      color: '#fe6031',
    },
  },
}))(InputLabel)

export const CustomInput = withStyles((theme) => ({
  root: {
    fontFamily: 'var(--font-lato)',
    'label + &': {
      marginTop: '21px',
    },
    '&.Mui-error .MuiInputBase-input': {
      borderColor: '#fe6031',
    },
  },
  input: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: '48px',
    boxSizing: 'border-box',
    padding: '9px 16px',
    width: '100%',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '30px',
    fontFamily: 'var(--font-lato)',
    borderRadius: 12,
    border: '1px solid #D2D2D7',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#9B6EFF',
    },
    '&::placeholder': {
      color: '#D2D2D7',
      opacity: 1,
    },
  },
}))(InputBase)
