import React, { useEffect, useState } from 'react'

import FormControl from '@mui/material/FormControl'

import { styled } from '@mui/material/styles'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'

import { CustomLabel } from '../CustomInput'

import classes from '../Input.module.scss'

import { Input as InputType } from '../Input'

export const CustomKeyboardDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  root: {
    fontFamily: 'var(--font-lato)',
  },
  'label + &': {
    paddingTop: '8px',
  },
  '&.Mui-error .MuiInputBase-input': {
    borderColor: '#fe6031',
  },
  input: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: '48px',
    boxSizing: 'border-box',
    padding: '9px 16px',
    width: '100%',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '30px',
    fontFamily: 'Lato, -apple-system, Roboto, sans-serif',
  },
  // '& label': {
  // color: 'white',
  // },
  // '& label.Mui-focused': {
  // color: 'white',
  // },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    // color: 'white',
    borderRadius: 12,
    '& fieldset': {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      borderColor: '#D2D2D7',
    },
    '&:hover fieldset': {
      borderColor: '#9B6EFF',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #9B6EFF',
    },
  },
}))

type TInputDate = {
  value?: React.SetStateAction<Date>
  defaultValue?: Date
  onChange: (date: any) => void
} & InputType

export default function InputDate({ id, label, error, onChange, helperText, value }: TInputDate) {
  const [inputValue, setInputValue] = useState(new Date())
  const [inputError, setInputError] = useState<string | null>('')

  let helperTextClasses = classes.helper

  useEffect(() => {
    if (value) {
      setInputValue(value)
    }
  }, [value])

  if (error || inputError) {
    helperTextClasses += ` ${classes.helper_danger}`
  }

  const errorText = helperText || inputError !== 'null' || ''

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl className={classes.root} error={error}>
        {label && (
          <CustomLabel shrink htmlFor={id}>
            {label}
          </CustomLabel>
        )}
        <CustomKeyboardDatePicker
          format="MM/dd/yyyy"
          value={inputValue}
          onChange={onChange}
          onError={(err) => setInputError(`${err}`)}
          slotProps={{
            popper: {
              disablePortal: true,
            },
          }}
        />
        {errorText && <span className={helperTextClasses}>{errorText}</span>}
      </FormControl>
    </LocalizationProvider>
  )
}
